const routes = {
    home: {path: "/"},
    admin: {path: "/admin"},
    adminUsers: {path: "/admin/users"},
    adminCourses: {path: "/admin/courses"},
    adminSubscriptions: {path: "/admin/subscriptions"},
    adminFeaturedAddCourse: {path: "/admin/featured/courses/add"},
    adminFeaturedAddContent: {path: "/admin/featured/content/add"},
    adminFeaturedAddSchool: {path: "/admin/featured/schools/add"},
    adminFeatured: {path: "/admin/featured"},
    adminProfile: {path: "/admin/profile/:id"},
    signup: {path: "/signup"},
    signin: {path: "/signin"},
    profile: {path: "/profile"},
    profileById: {path: "/profile/:id"},
    profileEdit: {path: "/profile/edit"},
    courses: {path: "/courses"},
    selfStudycourses: {path: "/self-study-courses"},
    selfStudycourse: {path: "/self-study-courses/:id"},
    selfStudycourseLecture: {path: "/self-study-courses/:id/Lecture"},
    course: {path: "/courses/:id"},
    coursesAdd: {path: "/courses/add"},
    coursesAddWithId: {path: "/courses/add/:id"},
    coursesEdit: {path: "/courses/edit/:id"},
    coursesRequested: {path: "/courses/requested"},
    coursesRequestedEdit: {path: "/courses/requested/edit/:id"},
    coursesSearch: {path: "/courses/search"},
    programs: {path: "/programs"},
    program: {path: "/programs/:id"},
    meetings: {path: "/meetings"},
    children: {path: "/children"},
    reset: {path: "/reset"},
    quizzes: {path: "/quizzes"},
    library: {path: "/library"},
    invitations: {path: "/invitations"},
    lectures: {path: "/lectures"},
    lecture: {path: "/lectures/:id"},
    lectureDigest: {path: "/lectures/:id/digest"},
    meetingDigest: {path: "/meetings/:id/digest"},
    lectureSelfStudy: {path: "/lectures/:id/self-study"},
    lectureReview: {path: "/lectures/:id/review"},
    lectureAssignment: {path: "/lectures/:id/assignment/:assignmentId"},
    wallet: {path: "/wallet"},
    balance: {path: "/wallet/balance"},
    walletSubscriptions: {path: "/wallet/subscriptions"},
    walletPacks: {path: "/wallet/packs"},
    walletHistory: {path: "/wallet/history"},
    walletVouchers: {path: "/wallet/vouchers"},
    weightageCategory: {path: "/weightage-categories"},
    gradingSchema: {path: "/gradingSchema"},
    notifications: {path: "/notifications"},
    content: {path: "/content"},
    contentCreate: {path: "/content/create"},
    contentEdit: {path: "/content/:id/edit"},
    contentForPlayList: {path: "/content/:contentId/playlist"},
    contentForPublisherShow: {path: "/content/:contentId/:innerContentId"},

    contentShow: {path: "/content/:contentId"},
    market: {path: "/market"},
    browse: {path: "/browse"},
    shared: {path: "/shared"},
    sharedCourse: {path: "/shared/course/:id"},
    schools: {path: "/schools"},
    schoolLectures: {path: "/schools/lectures"},
    school: {path: "/schools/:id"},
    schoolEdit: {path: "/schools/edit/:id/"},
    schoolCreate: {path: "/schools/create"},
    schoolCourseCreate: {path: "/schools/:schoolId/courses/add/:id"},
    schoolCourseEdit: {path: "/schools/:schoolId/courses/edit/:id"},
    schoolList: {path: "/schools/list"},
    schoolProgramCreate: {path: "/schools/:schoolId/programs/add"},
    schoolProgramEdit: {path: "/schools/:schoolId/programs/edit/:id"},
    schoolProgramClone: {path: "/schools/:schoolId/programs/clone/:id"},
    teachers: {path: "/teachers"},
    teachersCreate: {path: "/teachers/create"},
    teachersInvite: {path: "/teachers/invite"},
    students: {path: "/students"},
    terms: {path: "/terms"},
    privacy: {path: "/privacy"},
    paymentTerms: {path: "/payment-terms"},
    callback: {path: "/callback"},
    chats: {path: "/chat"},
    chat: {path: "/chat/:id"},
    customPage: {path: "/:customPath"},
    myWork: {path: "/work"},
    ixl: {path: "/ixl"},
    assignments: {path: "/assignments"},
    assessments: {path: "/assessments"},
    grades: {path: "/grades"},

    // SMS
    mainDashboard: {path: "/main-dashboard"},
    subjects: {path: "/subjects"},
    subjectsAdd: {path: "/subjects/add"},
    subjectsEdit: {path: "/subjects/edit/:id"},
    subjectsView: {path: "/subjects/view/:id"},
    subjectCurriculums: {path: "/subject-curriculums"},
    subjectCurriculumsAdd: {path: "/subject-curriculums/add"},
    subjectCurriculumsEdit: {path: "/subject-curriculums/edit/:id"},
    subjectCurriculumsView: {path: "/subject-curriculums/view/:id"},
    teacherSubjects: {path: "/teacher-subjects"},
    teacherSubjectsAdd: {path: "/teacher-subjects/add"},
    teacherSubjectsEdit: {path: "/teacher-subjects/edit/:id"},
    teacherSubjectsView: {path: "/teacher-subjects/view/:id"},
    classes: {path: "/classes"},
    classesAdd: {path: "/classes/add"},
    classesEdit: {path: "/classes/edit/:id"},
    classesView: {path: "/classes/view/:id"},
    lessons: {path: "/lessons"},
    lessonsAdd: {path: "/lessons/add"},
    lessonsEdit: {path: "/lessons/edit/:id"},
    lessonsView: {path: "/lessons/view/:id"},
    studentAttendances: {path: "/student-attendances"},
    studentAttendancesAdd: {path: "/student-attendances/add"},
    studentAttendancesEdit: {path: "/student-attendances/edit/:id"}, // todo: view blm ada
    teacherAttendances: {path: "/teacher-attendances"},
    teacherAttendancesAdd: {path: "/teacher-attendances/add"},
    teacherAttendancesEdit: {path: "/teacher-attendances/edit/:id"}, // todo: view blm ada
    schoolBuildings: {path: "/school-buildings"},
    schoolBuildingsAdd: {path: "/school-buildings/add"},
    schoolBuildingsEdit: {path: "/school-buildings/edit/:id"},
    schoolBuildingsView: {path: "/school-buildings/view/:id"},
    dailyPeriods: {path: "/daily-periods"},
    dailyPeriodsAdd: {path: "/daily-periods/add"},
    dailyPeriodsEdit: {path: "/daily-periods/edit/:id"},
    dailyPeriodsView: {path: "/daily-periods/view/:id"},
    schedules: {path: "/schedules"},
    schedulesAdd: {path: "/schedules/add"},
    schedulesEdit: {path: "/schedules/edit/:id"},
    schedulesView: {path: "/schedules/view/:id"},
    smsStudents: {path: "/sms-students"},
    smsStudentsAdd: {path: "/sms-students/add"},
    smsStudentsEdit: {path: "/sms-students/edit/:id"},
    smsStudentsView: {path: "/sms-students/view/:id"},
    feeTypes: {path: "/fee-types"},
    feeTypesAdd: {path: "/fee-types/add"},
    feeTypesEdit: {path: "/fee-types/edit/:id"},
    feeTypesView: {path: "/fee-types/view/:id"},
    fees: {path: "/fees"},
    feesAdd: {path: "/fees/add"},
    feesEdit: {path: "/fees/edit/:id"},
    feesView: {path: "/fees/view/:id"},
    feePayments: {path: "/fee-payments"},
    feePaymentsAdd: {path: "/fee-payments/add"},
    feePaymentsEdit: {path: "/fee-payments/edit/:id"},
    feePaymentsView: {path: "/fee-payments/view/:id"},
    enrollments: {path: "/enrollments"},
    enrollmentsAdd: {path: "/enrollments/add"},
    enrollmentsEdit: {path: "/enrollments/edit/:id"},
    enrollmentsView: {path: "/enrollments/view/:id"},
    schoolWeekdays: {path: "/school-weekdays"},
    schoolWeekdaysAdd: {path: "/school-weekdays/add"},
    schoolWeekdaysEdit: {path: "/school-weekdays/edit/:id"},
    schoolWeekdaysView: {path: "/school-weekdays/view/:id"},
    exams: {path: "/exams"},
    examsAdd: {path: "/exams/add"},
    examsEdit: {path: "/exams/edit/:id"},
    examsView: {path: "/exams/view/:id"},
    examResults: {path: "/exam-results"},
    examResultsAdd: {path: "/exam-results/add"},
    examResultsEdit: {path: "/exam-results/edit/:id"},
    examResultsView: {path: "/exam-results/view/:id"}
};

const getRoute = (route, params = {}, extraPath = "") => {
    const routePath = typeof route === "object" ? route.path : route;
    const basePath = Object.keys(params).reduce((acc, key) => acc.replace(`:${key}`, params[key]), routePath);
    return basePath + extraPath;
};

export {getRoute};
export default routes;
