import React from "react";
import userTypes from "consts/userTypes";
import routes, {getRoute} from "consts/routes";
import StorefrontIcon from "@mui/icons-material/Storefront";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SchoolIcon from "@mui/icons-material/School";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import MonitorIcon from "@mui/icons-material/Monitor";
import PlayLessonIcon from "@mui/icons-material/PlayLesson";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import SourceIcon from "@mui/icons-material/Source";
import GroupIcon from "@mui/icons-material/Group";
import SearchTutorCourseIcon from "components/common/icons/SearchTutorCourse";
import {DashboardRounded} from "@mui/icons-material";

export default ({currentUser}) => {
    const hasSchool = currentUser.schoolSettings && currentUser.schoolSettings.school;
    const userSchoolId = hasSchool && currentUser.schoolSettings.school.id;
    return [
        {
            title: "navigation.menu.mySchool",
            link: hasSchool ? getRoute(routes.school.path, {id: userSchoolId}) : routes.schoolCreate.path,
            availableForUsers: [userTypes.principal],
            icon: <SchoolIcon />
        },
        {
            title: "navigation.menu.schoolManagement",
            link: routes.mainDashboard.path,
            availableForUsers: [userTypes.principal],
            id: "registeredMainDashboardButton",
            icon: <DashboardRounded />
        },
        {
            title: "navigation.menu.schoolLectures",
            link: routes.schoolLectures.path,
            availableForUsers: [userTypes.principal],
            icon: <MonitorIcon />
        },
        {
            title: "navigation.menu.teachers",
            link: routes.teachers.path,
            availableForUsers: [userTypes.principal],
            id: "registeredHeaderTeachersButton",
            icon: <PersonOutlineIcon />
        },
        {
            title: "navigation.menu.students",
            link: routes.students.path,
            availableForUsers: [userTypes.principal],
            id: "registeredHeaderStudentsButton",
            icon: <GroupIcon />
        },
        {
            title: "navigation.menu.selfStudyCourses",
            link: routes.selfStudycourses.path,
            availableForUsers: [userTypes.teacher, userTypes.student, userTypes.parent, userTypes.child],
            id: "registeredHeaderSelfStudyCoursesButton",
            icon: <PlayLessonIcon />
        },
        {
            title: "navigation.menu.liveCourses",
            link: routes.courses.path,
            availableForUsers: [userTypes.teacher, userTypes.student, userTypes.parent, userTypes.child],
            id: "registeredHeaderMyCoursesButton",
            icon: <CastForEducationIcon />
        },
        {
            title: "navigation.menu.myContent",
            link: routes.content.path,
            availableForUsers: [userTypes.publisher],
            icon: <SourceIcon />
        },
        {
            title: "navigation.menu.lectures",
            link: routes.lectures.path,
            availableForUsers: [userTypes.teacher, userTypes.student, userTypes.parent, userTypes.child],
            icon: <MonitorIcon />
        },
        {
            title: "navigation.menu.myWork",
            link: routes.myWork.path,
            availableForUsers: [userTypes.student, userTypes.parent, userTypes.child, userTypes.teacher, userTypes.principal],
            id: "registeredHeaderMyWordButton",
            icon: <AssignmentIcon />
        },
        {
            title: "navigation.menu.grades",
            link: routes.grades.path,
            availableForUsers: [userTypes.student, userTypes.child, userTypes.teacher, userTypes.principal, userTypes.parent],
            id: "registeredHeadergradesButton",
            icon: <MenuBookIcon />
        },
        {
            title: "navigation.menu.adHoc",
            link: routes.meetings.path,
            availableForUsers: [userTypes.principal, userTypes.teacher, userTypes.student, userTypes.parent, userTypes.child],
            id: "registeredHeaderMeetingsButton",
            icon: <VideoCameraFrontIcon />
        },
        {
            title: "navigation.menu.market",
            link: routes.market.path,
            availableForUsers: [
                userTypes.teacher,
                userTypes.student,
                userTypes.parent,
                userTypes.child,
                userTypes.admin,
                userTypes.publisher,
                userTypes.principal
            ],
            id: "registeredHeaderMarketButton",
            icon: <StorefrontIcon />
        },
        {
            title: "navigation.menu.browse",
            link: routes.browse.path,
            availableForUsers: [userTypes.student, userTypes.parent, userTypes.child, userTypes.teacher],
            id: "registeredHeaderBrowseButton",
            // icon: <TravelExploreIcon />
            icon: <SearchTutorCourseIcon />
        },
        // {
        //     title: "navigation.menu.ixl",
        //     link: routes.ixl.path,
        //     availableForUsers: [userTypes.student, userTypes.child],
        //     id: "registeredHeaderIxlButton"
        // },
        {
            title: "navigation.menu.users",
            link: routes.adminUsers.path,
            availableForUsers: [userTypes.admin],
            id: "registeredHeaderUsersButton"
        },
        {
            title: "navigation.menu.allCourses",
            link: routes.adminCourses.path,
            availableForUsers: [userTypes.admin]
        },
        {
            title: "navigation.menu.subscriptions",
            link: routes.adminSubscriptions.path,
            availableForUsers: [userTypes.admin]
        },
        {
            title: "navigation.menu.featured",
            link: routes.adminFeatured.path,
            availableForUsers: [userTypes.admin]
        }
    ];
};
