import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {withContextConsumer} from "utils/contexts";
import LoginContext from "contexts/Login";
import CurrentUserContext from "contexts/CurrentUser";
import NotRegistered from "components/header/NotRegistered";
import Registered from "components/header/Registered";
import LandingHeader from "components/header/LandingHeader";
import routes from "consts/routes";
import {matchRoute, routesSMS, routesWithoutHeader} from "./HeaderSpecialRoutes";
import SmsLayout from "../../components/header/SmsLayout";

@withRouter
@withContextConsumer(LoginContext.Consumer)
@withContextConsumer(CurrentUserContext.Consumer)
class Header extends Component {
    constructor(props) {
        super(props);
        const {pathname, host, port} = window.location;
        const isWeblateUrl = pathname === "/weblate";
        const isWeblatePort = host === "staging.tabsera.com" && port === "1234";
        if (isWeblatePort || isWeblateUrl) {
            window.location.assign("http://152.67.134.110:1234/");
        }
    }

    getNotRegisteredHeader() {
        return <NotRegistered />;
    }

    getRegisteredHeader() {
        const {currentUser, logout} = this.props;
        return <Registered currentUser={currentUser} logout={logout} />;
    }

    getSMSHeader() {
        const {currentUser, logout, t} = this.props;
        return <SmsLayout currentUser={currentUser} logout={logout} t={t} />;
    }

    getLandingHeader() {
        const {currentUser, forwardedRef, scrollbar, scrollbarRef} = this.props;
        return (
            <LandingHeader
                scrollbar={scrollbar}
                currentUser={currentUser}
                forwardedRef={forwardedRef}
                scrollbarRef={scrollbarRef}
            />
        );
    }

    render() {
        const {currentUser, history} = this.props;
        const path = history.location.pathname;
        const isLanding = path === routes.home.path;
        const isUnregisteredCustomPage = !currentUser && !routesWithoutHeader.find(r => r === path);

        if (isLanding || isUnregisteredCustomPage) return this.getLandingHeader();

        if (currentUser) {
            const isSmsPage = currentUser?.role === "principal" && matchRoute(path, routesSMS);

            return isSmsPage ? this.getSMSHeader() : this.getRegisteredHeader();
        }

        return this.getNotRegisteredHeader();
    }
}

export default Header;
